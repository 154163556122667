<template>
  <div class="body">
    <div class="header">
      <div class="headContent">
        <div class="topbar">
          <div class="logo">
            <a href="https://micronagritech.com">
              <img
                style="max-width: 80px; max-height: 80px"
                alt="Micron Agritech"
                src="@/assets/micron_teal.png"
            /></a>
          </div>
          <div class="content">
            <a href="https://micronagritech.com" class="link">Home</a>
            <a href="https://micronagritech.com/contact-us/" class="link"
              >Contact Us</a
            >
            <a
              href="https://micronagritech.com/request-a-demo/"
              class="btn btn-primary"
              style="margin-left: 20px"
              >Request a Demo
            </a>
          </div>
        </div>
        <div class="shoutout">
          <p>
            The only app to rapidly test animals for worms
            <b style="color: #35bba4">on-site</b>
            and in
            <b style="color: #35bba4">real-time</b>.
          </p>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="maincontent">
        <p class="login-title">
          Login to the web portal to view results, assign credits and more.
        </p>
        <form class="loginbox" v-on:submit.prevent="LoginUser">
          <div class="error" v-for="error in ErrorMsg" :key="error">
            {{ error }}
          </div>
          <input
            type="email"
            v-model="this.EmailAddress"
            class="input"
            placeholder="Email Address"
          />
          <input
            type="password"
            v-model="this.Password"
            class="input"
            placeholder="Password"
          />
          <input
            type="submit"
            name=""
            id=""
            class="btn btn-primary"
            value="Login"
          />
          <router-link
            style="
              text-align: right;
              float: right;
              text-decoration: underline;
              font-size: 17px;
              color: #35bba4;
              font-weight: 600;
              line-height: 40px;
            "
            :to="{ name: 'ForgotPassword' }"
            >Forgotten your password?</router-link
          >
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "Login",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      EmailAddress: "",
      Password: "",
      ErrorMsg: [],
      disableBtn: false,
    };
  },
  methods: {
    LoginUser() {
      this.disableBtn = true;
      this.store
        .dispatch("User/User_Login", {
          email: this.EmailAddress,
          password: this.Password,
        })
        .then((result) => {
          console.log(result);
          this.$router.push({ name: "Home" });
        })
        .catch((err) => {
          this.disableBtn = false;
          console.log(err);
          this.ErrorMsg = err.messages;
        });
    },
  },
  created() {
    this.store
      .dispatch("User/CheckAuth")
      .then((data) => {
        console.log(data);
        this.$router.push({ name: "Home" });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped>
body {
  overflow-x: hidden;
}

.body {
  background-color: #ffffff;
  height: 100vh;
}

.header {
  height: auto;
  width: 100%;
  background: rgba(53, 187, 164, 0.1);
}

.headContent {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  max-width: 1920px;
  box-sizing: border-box;
}
.topbar {
  height: 100px;
  width: 100%;
  display: block;
  line-height: 100px;
}

.logo {
  padding-top: 15px;
  max-width: 70px;
  max-height: 70px;
  display: inline-block;
}

.content {
  padding: 0px 15px;
  height: 100px;
  line-height: 100px;
  color: #131313;
  font-size: 22px;
  margin-right: 10px;
  float: right;
}

.btn {
  width: auto;
  padding: 0px 20px;
  height: 50px;
  line-height: 50px;
  color: #131313;
  font-weight: bold;
  font-size: 18px;
  border-radius: 15px 15px;
  cursor: pointer;
  box-shadow: 0px 5px 10px 0px #00000036;
  font-family: Arial, Helvetica, sans-serif;
  top: -5px;
  position: relative;
}

.btn-primary {
  background-color: #35bba4;
  color: #fff;
}

.shoutout,
.shoutout p {
  margin-top: 80px;
  font-size: 32px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  color: #131313;
  display: block;
  width: 600px;
}

.link {
  display: inline-block;
  height: 100px;
  padding: 0px 20px;
  font-size: 30px;
  line-height: 100px;
  color: #0e0e0e;
}

.placeholder-login {
  font-family: Arial, sans-serif;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  background: #ffffff;
}

.main {
  width: 100%;
  height: auto;
  display: block;
}

.maincontent {
  max-width: 1920px;
  width: 80%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.login-title {
  width: 40%;
  height: auto;
  display: block;
  font-size: 22px;
  color: #4d4d4d;
  font-family: Arial, Helvetica, sans-serif;
}

.loginbox {
  width: 40%;
  height: auto;
  display: inline-block;
}

.loginbox .input {
  height: 50px;
  font-size: 27px;
  width: 100%;
  color: #4d4d4d !important;
  display: block;
  padding: 0px 5px;
  background: #f2f2f2 !important;
  outline: 0;
  border: 1px solid #c8ced5;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 0px 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.loginbox .input::placeholder {
  color: #9a9a9a;
}

.loginbox .input:hover,
.loginbox .input:focus {
  border-color: #35bba4;
  background: #fff !important;
  transition: 0.3s ease-in-out;
}

.image {
  position: absolute;
  width: 50%;
  display: inline-block;
  top: 220px;
  right: 0px;
  max-width: fit-content;
}

.error {
  height: auto;
  width: 100%;
  background: #e34343;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #c52f2f;
  margin-bottom: 20px;
}

/* If screen 900px or less. */
@media only screen and (max-width: 910px) {
  .topbar .link {
    display: none;
  }

  .loginbox {
    width: 100%;
    height: auto;
    display: inline-block;
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .image {
    position: absolute;
    width: 50%;
    display: none;
    top: 220px;
    right: 0px;
    max-width: fit-content;
  }

  .headContent {
    max-width: 95%;
    width: 95%;
  }

  .maincontent {
    max-width: 95%;
    width: 95%;
    height: auto;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    padding-bottom: 50px;
  }

  .login-title {
    width: 100%;
    height: auto;
    display: block;
    font-size: 22px;
    color: #4d4d4d;
    padding: 0px 5px;
    box-sizing: border-box;
  }

  .shoutout,
  .shoutout p {
    margin-top: 80px;
    font-size: 32px;
    font-weight: 700;
    font-family: Arial, Helvetica, sans-serif;
    color: #131313;
    display: block;
    width: 100%;
    padding: 0px 5px;
    box-sizing: border-box;
  }
}
</style>